var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":("" + (_vm.$t('profile.title')))}},[_c('validation-observer',{ref:"profileUpdateForm"},[(_vm.user !== null)?_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.name'))),"label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false : null,"placeholder":"johndoe"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2386776876)})],1),_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.email'))),"label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2285265358)})],1),_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.phone'))),"label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('+## (##) #####-####'),expression:"'+## (##) #####-####'"}],attrs:{"id":"phone","name":"phone","state":errors.length > 0 ? false : null,"placeholder":"+XX (XX) XXXXX-XXXX"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,646597212)})],1),_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.password'))),"label-for":"register-password"}},[_c('validation-provider',{attrs:{"vid":"password","name":"Password","rules":"min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3986354264)})],1),_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.confirm_password'))),"label-for":"confirmPassword"}},[_c('validation-provider',{attrs:{"name":"confirmPassword","rules":"min:6|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirmPassword","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"confirmPassword","placeholder":"············"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3419125674)})],1),_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.document_type'))),"label-for":"document_type"}},[_c('validation-provider',{attrs:{"name":"document_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"document_type","name":"document_type","options":_vm.documentTypeOptions.person,"state":errors.length > 0 ? false : null},model:{value:(_vm.document_type),callback:function ($$v) {_vm.document_type=$$v},expression:"document_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4219248916)})],1),_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.document_number'))),"label-for":"document_number"}},[_c('validation-provider',{attrs:{"name":"document_number","rules":"min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"document_number","name":"document_number","state":errors.length > 0 ? false : null,"placeholder":"xxxxxxxxxxxx"},model:{value:(_vm.document_number),callback:function ($$v) {_vm.document_number=$$v},expression:"document_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3847797630)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"wildcard"}},[_vm._v(_vm._s(_vm.$t('register.form.document_file')))]),_c('validation-provider',{attrs:{"name":"document_file"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"wildcard","name":"document_file","accept":"image/*, .pdf","browse-text":("" + (_vm.$t('register.form.select_file'))),"placeholder":("" + (_vm.$t('register.form.no_file_selected')))},model:{value:(_vm.document_file),callback:function ($$v) {_vm.document_file=$$v},expression:"document_file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3483630089)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('profile.form.button_submit'))+" ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }