<template>
  <b-card :title="`${$t('profile.title')}`">
    <!-- form -->
    <validation-observer ref="profileUpdateForm">
      <b-form
        v-if="user !== null"
        class="auth-register-form mt-2"
      >
        <!-- username -->
        <b-form-group
          :label="`${$t('register.form.name')}`"
          label-for="register-username"
        >
          <validation-provider
            #default="{ errors }"
            name="Username"
            rules="required"
          >
            <b-form-input
              id="register-username"
              v-model="user.name"
              name="register-username"
              :state="errors.length > 0 ? false : null"
              placeholder="johndoe"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- email -->
        <b-form-group
          :label="`${$t('register.form.email')}`"
          label-for="register-email"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              id="register-email"
              v-model="user.email"
              name="register-email"
              :state="errors.length > 0 ? false : null"
              placeholder="john@example.com"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- phone -->
        <b-form-group
          :label="`${$t('register.form.phone')}`"
          label-for="phone"
        >
          <validation-provider
            #default="{ errors }"
            name="phone"
          >
            <b-form-input
              id="phone"
              v-model="user.phone"
              v-mask="'+## (##) #####-####'"
              name="phone"
              :state="errors.length > 0 ? false : null"
              placeholder="+XX (XX) XXXXX-XXXX"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- password -->
        <b-form-group
          :label="`${$t('register.form.password')}`"
          label-for="register-password"
        >
          <validation-provider
            #default="{ errors }"
            vid="password"
            name="Password"
            rules="min:6"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="register-password"
                v-model="password"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                name="register-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="`${$t('register.form.confirm_password')}`"
          label-for="confirmPassword"
        >
          <validation-provider
            #default="{ errors }"
            name="confirmPassword"
            rules="min:6|confirmed:password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="confirmPassword"
                v-model="confirmPassword"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                name="confirmPassword"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="`${$t('register.form.document_type')}`"
          label-for="document_type"
        >
          <validation-provider
            #default="{ errors }"
            name="document_type"
          >
            <b-form-select
              id="document_type"
              v-model="document_type"
              name="document_type"
              :options="documentTypeOptions.person"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="`${$t('register.form.document_number')}`"
          label-for="document_number"
        >
          <validation-provider
            #default="{ errors }"
            name="document_number"
            rules="min:6"
          >
            <b-form-input
              id="document_number"
              v-model="document_number"
              name="document_number"
              :state="errors.length > 0 ? false : null"
              placeholder="xxxxxxxxxxxx"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="wildcard">{{ $t('register.form.document_file') }}</label>
          <validation-provider
            #default="{ errors }"
            name="document_file"
          >
            <b-form-file
              id="wildcard"
              v-model="document_file"
              name="document_file"
              accept="image/*, .pdf"
              :browse-text="`${$t('register.form.select_file')}`"
              :placeholder="`${$t('register.form.no_file_selected')}`"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          variant="primary"
          block
          type="submit"
          @click.prevent="validationForm"
        >
          {{ $t('profile.form.button_submit') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
} from 'bootstrap-vue'
// import store from '@/store/index'
import { userService } from '@/services/user/list'
import { userUpdateService } from '@/services/user/update'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email, min } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  directives: { mask },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormFile,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userService: userService(this.$http),
      userUpdateService: userUpdateService(this.$http),
      user: null,
      password: '',
      confirmPassword: '',
      required,
      min,
      email,
      document_type: '',
      document_number: '',
      document_file: [],
      documentTypeOptions: {
        person: [
          { value: '', text: 'Selecione', disabled: true },
          { value: 'RG', text: 'RG' },
          { value: 'CPF', text: 'CPF' },
          { value: 'PASSAPORTE', text: 'PASSAPORTE' },
          { value: 'ID', text: 'ID' },
        ],
        company: [
          { value: 'CNPJ', text: 'CNPJ', disabled: true },
        ],
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...mapGetters({
      getUser: 'users/user',
    }),
  },
  async created() {
    this.user = await this.userService.getUser(`uuid=${this.getUser.uuid}`)
  },
  methods: {
    validationForm() {
      this.$refs.profileUpdateForm.validate().then(async success => {
        if (success) {
          try {
            const data = {
              user_uuid: this.getUser.uuid,
              name: this.user.name,
              email: this.user.email,
              password: this.password,
              password_confirmation: this.confirmPassword,
              register_type: this.selectedRegisterType,
              phone: this.user.phone,
            }

            if (this.document_type !== '') {
              const arquivo = await toBase64(this.document_file)
              data.document = [{
                type: this.document_type,
                number: this.document_number,
                file: arquivo,
              }]
            }
            await this.userUpdateService.update(data)
            this.$swal({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Seu cadastro foi atualizado com sucesso!',
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro ao atualizar perfil.',
                text: error.response.data.message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
  },
}
</script>
