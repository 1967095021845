const userUpdateService = http => {
  const endpointAdmin = `${process.env.VUE_APP_API_BASE_URL}admin/user/alter`

  const update = data => http.post(endpointAdmin, data)

  return {
    update,
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  userUpdateService,
}
